<template>
  <b-card no-body>
    <b-card-body>

      <!-- date filter -->
      <b-row>
        <div class="d-flex flex-wrap flex-sm-nowrap flex-column flex-sm-column flex-md-row flex-dates">
          <div class="d-flex justify-content-start justify-content-sm-start dates-filter pb-1 pb-md-0">
            <div
              class="pr-1 align-self-center date-prefix-title flex-shrink-0"
            >
              {{ $t('From') }} :
            </div>
            <div class="pr-1 align-self-center">
              <b-form-datepicker
                v-model="dateStartValue"
                :date-format-options="{ year: 'numeric', day: '2-digit', month: '2-digit' }"
                locale="th"
                class="datepicker-input"
              />
            </div>
            <div class="p-0 align-self-center">
              <b-form-select
                v-model="timeStartValue"
                :options="optionsTimes"
                class="date-select"
              />
            </div>
          </div>
          <div class="d-flex justify-content-start justify-content-sm-start dates-filter pb-1 pb-md-0 pr-1">
            <div
              class="pr-1 pl-1 pl-xs-0 align-self-center date-prefix-title flex-shrink-0"
            >
              {{ $t('To') }} :
            </div>
            <div class="pr-1 align-self-center">
              <b-form-datepicker
                v-model="dateEndValue"
                :date-format-options="{ year: 'numeric', day: '2-digit', month: '2-digit' }"
                locale="th"
                class="datepicker-input"
              />
            </div>
            <div class="p-0 align-self-center">
              <b-form-select
                v-model="timeEndValue"
                :options="optionsTimes"
                class="date-select"
              />
            </div>
          </div>
          <div class="d-flex justify-content-start justify-content-sm-start dates-filter pb-1 pb-md-0 pr-1">
            <div class="p-0 align-self-center">
              <b-form-select
                :value="quickDate"
                :options="optionsQuickDate"
                @change="onChangeSelectQuickDate"
              />
            </div>
          </div>
        </div>
        <div class="d-flex flex-row pt-0 pb-1 pt-sm-0 pb-sm-1 pt-md-1 pb-md-1">
          <div
            v-if="isSearchUsername"
            class="pr-1"
          >
            <b-form-input
              v-model.trim="searchUsernameValue"
              :placeholder="$t('Username')"
              maxlength="32"
            />
          </div>
          <div>
            <!-- <b-button
              variant="primary"
              @click.prevent="$emit('onSearch')"
            >
              <feather-icon
                icon="SearchIcon"
              />
              <span class="align-middle">
                {{ $wait.is('reports-loading') ? 'loading' : 'Search' }}
              </span>
            </b-button> -->
            <Button
              variant="primary"
              icon="SearchIcon"
              :loading="$wait.is('reports-loading')"
              :text="$t('Search')"
              @click="$emit('onSearch')"
            />
          </div>
        </div>
      </b-row>
      <!-- end date filter -->
      <b-row
        align-v="center"
      >
        <div class="d-flex align-items-center w-100">
          <div class="">
            {{ $t('Game Type') }} :
          </div>
          <div class="pl-1 flex-grow-1 flex-sm-grow-0 w-25">
            <b-form-select
              v-model="selectedGameValue"
              :options="optionsGames"
              @change="onChangeSelectGame"
            />
          </div>
        </div>
      </b-row>
      <b-row align-v="center">
        <div class="d-flex flex-wrap">
          <b-form-checkbox-group
            v-model="selectedGameCheckboxsValue"
            :options="optionsGameCheckboxs"
            class="demo-inline-spacing custom-control-success"
            @change="onChangeSelectedGameCheckbox"
          />
        </div>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker, BFormSelect, BFormGroup, BFormCheckboxGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { GameLists } from '@/utils/report'
import Button from '@/components/Button.vue'
import {
  getQuickDateValue,
  quickDateLists,
  parseQuickDateMatched,
} from '@/utils/date.util'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormSelect,
    BFormDatepicker,
    BFormGroup,
    BFormCheckboxGroup,
    BFormInput,
    BButton,
    Button,
  },
  props: {
    dateStart: { type: String, required: true },
    dateEnd: { type: String, required: true },
    timeStart: { type: String, required: true },
    timeEnd: { type: String, required: true },
    selectedGame: { type: String, required: true },
    selectedGameCheckboxs: { type: Array, required: true },
    searchUsername: { type: String, required: true },
    isSearchUsername: { type: Boolean, required: true },
  },
  data() {
    return {
      // dateStart: new Date(),
      // dateEnd: new Date(),
      // timeStart: '11.00',
      // timeEnd: '11.00',
      optionsTimes: [
        { text: '00:00', value: '00:00' },
        { text: '01:00', value: '01:00' },
        { text: '02:00', value: '02:00' },
        { text: '03:00', value: '03:00' },
        { text: '04:00', value: '04:00' },
        { text: '05:00', value: '05:00' },
        { text: '06:00', value: '06:00' },
        { text: '07:00', value: '07:00' },
        { text: '08:00', value: '08:00' },
        { text: '09:00', value: '09:00' },
        { text: '10:00', value: '10:00' },
        { text: '11:00', value: '11:00' },
        { text: '12:00', value: '12:00' },
        { text: '13:00', value: '13:00' },
        { text: '14:00', value: '14:00' },
        { text: '15:00', value: '15:00' },
        { text: '16:00', value: '16:00' },
        { text: '17:00', value: '17:00' },
        { text: '18:00', value: '18:00' },
        { text: '19:00', value: '19:00' },
        { text: '20:00', value: '20:00' },
        { text: '21:00', value: '21:00' },
        { text: '22:00', value: '22:00' },
        { text: '23:00', value: '23:00' },
      ],

      // selectedGame: '',
      optionsGames: [
        { text: this.$t('All'), value: 'All' },
        { text: this.$t('Custom'), value: 'Custom' },
        ...GameLists,
      ],

      // selectedGameCheckboxs: [],
      optionsGameCheckboxs: [
        ...GameLists,
      ],

      quickDate: '',
    }
  },
  computed: {
    optionsQuickDate() {
      return [{ value: '', text: this.$t('Quick Pick Date'), disabled: true }, ...Array.from(quickDateLists).map(list => ({ text: this.$t(list.text), value: list.value }))]
    },
    dateStartValue: {
      get() {
        return this.dateStart
      },
      set(val) {
        this.$emit('update:dateStart', val)
      },
    },
    dateEndValue: {
      get() {
        return this.dateEnd
      },
      set(val) {
        this.$emit('update:dateEnd', val)
      },
    },
    timeStartValue: {
      get() { return this.timeStart },
      set(val) { this.$emit('update:timeStart', val) },
    },
    timeEndValue: {
      get() { return this.timeEnd },
      set(val) { this.$emit('update:timeEnd', val) },
    },
    selectedGameValue: {
      get() { return this.selectedGame },
      set(val) { this.$emit('update:selectedGame', val) },
    },
    selectedGameCheckboxsValue: {
      get() { return this.selectedGameCheckboxs },
      set(val) { this.$emit('update:selectedGameCheckboxs', val) },
    },
    searchUsernameValue: {
      get() { return this.searchUsername },
      set(val) { this.$emit('update:searchUsername', val) },
    },
  },
  watch: {
    quickDate(quickDateKey) {
      const quickDate = this.getQuickDateValue(quickDateKey)
      const { dateStart } = quickDate
      const { dateEnd } = quickDate
      const { timeStart } = quickDate
      const { timeEnd } = quickDate
      this.$nextTick(() => this.$emit('update:dateStart', dateStart))
      this.$nextTick(() => this.$emit('update:dateEnd', dateEnd))
      this.$nextTick(() => this.$emit('update:timeStart', timeStart))
      this.$nextTick(() => this.$emit('update:timeEnd', timeEnd))
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.parseDateFromInit()
    })
  },
  methods: {
    getQuickDateValue,
    parseQuickDateMatched,
    parseDateFromInit() {
      const quickDateKey = this.parseQuickDateMatched({
        fromDateStart: this.dateStart,
        fromDateEnd: this.dateEnd,
        fromTimeStart: this.timeStart,
        fromTimeEnd: this.timeEnd,
      })
      if (quickDateKey) {
        this.quickDate = quickDateKey
      }
    },
    onChangeSelectQuickDate(value) {
      this.quickDate = value
    },
    onChangeSelectGame() {
      if (this.selectedGame === 'All') {
        // this.selectedGameCheckboxs = this.optionsGameCheckboxs.map(list => list.value)
        this.$emit('update:selectedGameCheckboxs', this.optionsGameCheckboxs.map(list => list.value))
      // eslint-disable-next-line no-empty
      } else if (this.selectedGame === 'Custom') {
      } else {
        // this.selectedGameCheckboxs = [this.selectedGame]
        this.$emit('update:selectedGameCheckboxs', [this.selectedGame])
        this.$emit('update:selectedGame', 'Custom')
      }
    },
    onChangeSelectedGameCheckbox() {
      // this.selectedGame = 'Custom'
      this.$emit('update:selectedGame', 'Custom')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.datepicker-input {
  white-space: nowrap!important;
  text-overflow: ellipsis!important;
  width: 140px;
  // min-width: 100px;
  // overflow: hidden;
}
.date-select {
  width: 100px;
}

@media (max-width: 576px) {
  .dates-filter .date-prefix-title {
    width: 60px;
  }

  .date-select {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .flex-dates {
    width: 100%;
  }
}

</style>
