const AgentTypes = ['AGENT', 'MASTER', 'SENIOR', 'PARTNER', 'COMPANY', 'API']
const AgentTypesLevel = [
  'AGENT',
  'MASTER',
  'SENIOR',
  'PARTNER',
  'COMPANY',
  'API',
]

const AgentDownlineListsByCurrentType = type => {
  const currentIndex = AgentTypes.findIndex(list => String(list).toLowerCase() === String(type).toLowerCase())
  if (currentIndex === -1) {
    return []
  }

  return AgentTypes.filter((_, index) => index < currentIndex)
}

const IsUpline = ({ uplineType, currentType }) => {
  const uplineIndex = AgentTypes.findIndex(list => String(list).toLowerCase() === String(uplineType).toLowerCase())
  const currentIndex = AgentTypes.findIndex(list => String(list).toLowerCase() === String(currentType).toLowerCase())
  return currentIndex > uplineIndex
}

// const IsReportUpline = ({ isSimple, uplineType, currentType }) => {
//   // always
//   // if (['COMPANY', 'API'].includes(currentType)) {
//   // console.log('test', { isSimple, uplineType, currentType })
//   // const isApi = uplineType === 'API'
//   // if (isApi) {
//   //   return currentType === 'API'
//   // }

//   const isCompany = uplineType === 'COMPANY'
//   if (isCompany) {
//     if (['COMPANY', 'API'].includes(currentType)) {
//       return true
//     }
//   }

//   if (!isSimple) {
//     // return true
//     return !IsUpline({ uplineType, currentType }) || (['COMPANY'].includes(currentType) && uplineType !== 'API')
//   }
//   if (!uplineType) {
//     return null
//   }

//   return uplineType === currentType || (['COMPANY'].includes(currentType) && uplineType !== 'API')
// }

const IsReportUpline = ({ isSimple, uplineType, currentType }) => {
  // always
  // if (['COMPANY', 'API'].includes(currentType)) {
  // console.log('test', { isSimple, uplineType, currentType })
  // const isApi = uplineType === 'API'
  // if (isApi) {
  //   return currentType === 'API'
  // }

  const isCompany = uplineType === 'COMPANY'
  if (isCompany) {
    if (['COMPANY', 'API'].includes(currentType)) {
      return true
    }
  }

  if (!isSimple) {
    // return true
    return !IsUpline({ uplineType, currentType }) || (['COMPANY'].includes(currentType) && uplineType !== 'API')
  }
  if (!uplineType) {
    return null
  }

  return uplineType === currentType || (['COMPANY'].includes(currentType) && uplineType !== 'API')
}

const GetAgentGroupId = agent => agent?.group?.groupId ?? agent._id

const GetAgentGroupUsername = agent => agent?.group?.groupUsername ?? agent.username

const GetAgentGroupType = agent => {
  const type = agent?.group?.groupType ?? agent.type
  return type
}

export {
  AgentTypes,
  IsUpline,
  IsReportUpline,
  AgentDownlineListsByCurrentType,
  GetAgentGroupId,
  GetAgentGroupUsername,
  GetAgentGroupType,
}

export const _ = undefined
